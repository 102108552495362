.loaderDiv {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    margin: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

    z-index: 40;
}

.loaderDiv .background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top:  0;
    left: 0;
    margin: 0;

    background-color: #202020;
    opacity: 75%;
}

.loaderDiv .wheel {
    border: 1rem solid #E0E0E0;
    border-top: 1rem solid #F04D22;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}