@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

@font-face {
    font-family: 'Freshman';
    src: url('/src/assets/fonts/Freshman.ttf') format('truetype');
    font-family: 'Gotham-Thin';
    src: url('/src/assets/fonts/Gotham-Thin.otf') format('opentype');
    font-family: 'Gotham-XLight';
    src: url('/src/assets/fonts/Gotham-XLight.otf') format('opentype');
    font-family: 'Gotham-Light';
    src: url('/src/assets/fonts/Gotham-Light.otf') format('opentype');
    font-family: 'Gotham-Book';
    src: url('/src/assets/fonts/Gotham-Book.otf') format('opentype');
    font-family: 'Gotham-Medium';
    src: url('/src/assets/fonts/Gotham-Medium.otf') format('opentype');
    font-family: 'Gotham-Bold';
    src: url('/src/assets/fonts/Gotham-Bold.otf') format('opentype');
    font-family: 'Gotham-Black';
    src: url('/src/assets/fonts/Gotham-Black.otf') format('opentype');
    font-family: 'Gotham-Ultra';
    src: url('/src/assets/fonts/Gotham-Ultra.otf') format('opentype');
    font-family: 'Gotham-ThinItalic';
    src: url('/src/assets/fonts/Gotham-ThinItalic.otf') format('opentype');
    font-family: 'Gotham-XLightItalic';
    src: url('/src/assets/fonts/Gotham-XLightItalic.otf') format('opentype');
    font-family: 'Gotham-LightItalic';
    src: url('/src/assets/fonts/Gotham-LightItalic.otf') format('opentype');
    font-family: 'Gotham-BookItalic';
    src: url('/src/assets/fonts/Gotham-BookItalic.otf') format('opentype');
    font-family: 'Gotham-MediumItalic';
    src: url('/src/assets/fonts/Gotham-MediumItalic.otf') format('opentype');
    font-family: 'Gotham-BoldItalic';
    src: url('/src/assets/fonts/Gotham-BoldItalic.otf') format('opentype');
    font-family: 'Gotham-BlackItalic';
    src: url('/src/assets/fonts/Gotham-BlackItalic.otf') format('opentype');
    font-family: 'Gotham-UltraItalic';
    src: url('/src/assets/fonts/Gotham-UltraItalic.otf') format('opentype');
}